import { AiOutlineArrowUp } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <section className="page">
      <div className="page__section">
        <div className="page__section--edit">
          <Link className="page__btn" to={'/'}>
            <AiOutlineArrowUp /> назад
          </Link>
        </div>

        <h3
          className="page__detals"
          style={{
            backgroundColor: '#fafac6',
            textAlign: 'center',
            padding: '30px',
            marginBottom: '50px',
          }}
        >
          Такої сторінки не існує
        </h3>
      </div>
    </section>
  );
};

export default ErrorPage;
