import { useRef, useState, useEffect } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const Select = ({ selectMap, label, selectedOption, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);

  // Selecting an option from the selector
  const handleSelect = option => {
    onSelect(option);
    setIsOpen(false);
  };

  // Close the selector selection
  const handleClickOutside = event => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  // Closing the selector selection when clicking outside its window
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="fomrIkc--select">
      <p className="formIkc--p">
        {label}
        <span style={{ color: 'red', fontFamily: 'sans-serif' }}>*</span>
      </p>
      <label
        className="labelForm__ikc"
        ref={containerRef}
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className=" inputForm--ikc">
          {selectedOption.length > 51
            ? selectedOption.slice(0, 50) + '...'
            : selectedOption}
        </p>
        {(isOpen && selectMap[0].name && (
          <ul className="selectVar">
            {selectMap.map(option => (
              <li
                className="formSelect--list"
                key={option.id}
                onClick={() => handleSelect(option)}
              >
                <p>{option.name}</p>
              </li>
            ))}
          </ul>
        )) ||
          (isOpen && (
            <ul className="selectVar">
              {selectMap.map(option => (
                <li
                  className="formSelect--list"
                  key={option}
                  onClick={() => handleSelect(option)}
                >
                  <p>{option}</p>
                </li>
              ))}
            </ul>
          ))}
        {isOpen ? (
          <IoIosArrowUp
            style={{ position: 'absolute', top: '12px', right: '15px' }}
          />
        ) : (
          <IoIosArrowDown
            style={{ position: 'absolute', top: '12px', right: '15px' }}
          />
        )}
      </label>
    </div>
  );
};
export default Select;
