import { Routes, Route } from 'react-router-dom';
import { Suspense, useEffect, useState } from 'react';
import { lazy } from 'react';
import { Circles } from 'react-loader-spinner';
import '../../index-style/index.css';
import { getAllCategory } from 'components/serviceApi/service';
import PageBreaks from 'page/pageSearch/pageBreaks';

import IkcPage from 'page/ikc/ikcPaga';
import ErrorPage from 'page/errorPage';
import CouterPage from 'page/pageSearch/counterPage';
import BreakGroup from 'page/breakGrop/breakGroup';
import ElectricityConnectionForm from 'page/breakGrop/vts';

const Layout = lazy(() => import('./Layout'));
const Header = lazy(() => import('../header/header'));
const Footer = lazy(() => import('../footer/footer'));
const Page = lazy(() => import('../../page/pageSearch/page'));
const PageSearch = lazy(() => import('../../page/pageSearch/pageSearch'));

export const App = () => {
  const [categories, setCategories] = useState([]);
  const [resCategory, setCategory] = useState(true);

  // Отримання категорій, і відложення лоадера...
  useEffect(() => {
    getAllCategory('').then(resp => setCategories(resp));
    if (categories.length === 0) {
      return setCategory(true);
    } else {
      return setCategory(false);
    }
  }, [categories.length]);

  return (
    <>
      <Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <Circles
              height={80}
              width={80}
              color="#337ab7"
              ariaLabel="circles-loading"
              visible={true}
            />
          </div>
        }
      >
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <Layout categories={categories} resCategory={resCategory} />
            }
          />
          <Route path="news/:id" element={<Page />} />
          <Route path="search/:find" element={<PageSearch />} />
          <Route path="breakPlan" element={<PageBreaks />} />
          <Route path="ikc" element={<IkcPage />} />
          <Route path="breakGroup" element={<BreakGroup />} />
          <Route path="vts/calc" element={<ElectricityConnectionForm />} />
          <Route path="counter" element={<CouterPage />} />
          <Route path="*" element={<ErrorPage error={404} />} />
        </Routes>
        <Footer />
      </Suspense>
    </>
  );
};
