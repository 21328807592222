import axios from 'axios';
import Notiflix from 'notiflix';
export const API = 'https://api.toe.com.ua/api/content';
// export const API = 'http://10.85.3.16:3001/api/content';

async function getAllCategory() {
  const resp = await axios.get(`${API}/category/list/`);
  if (resp.data) {
    return resp.data;
  }
  console.log('error');
}

// Get All News for main page
async function getAllNews(page) {
  const resp = await axios.get(`${API}/allnews/${page}`);
  if (resp) {
    return resp.data;
  }
  console.log('error');
}

// Get news for ID
async function getNewsById(id) {
  try {
    const resp = await axios.get(`${API}/idNews/${id}`);
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

// Find news for text
async function postSearchNewsByName(name, page) {
  try {
    const resp = await axios.get(`${API}/findnews/${name}/${page}/9`);
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

// All news for find
async function getAllNewsFind(page) {
  try {
    const resp = await axios.get(`${API}/findnews/allnews/${page}`);
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

// BreakPlan

async function getSearchBreakEmergency(breakData) {
  try {
    const resp = await axios.post(`${API}/breakPlan/emergency`, breakData);
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

async function getSearchBreakPlan(breakData) {
  try {
    const resp = await axios.post(`${API}/breakPlan/plan`, breakData);
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

async function getArchive(breakData) {
  try {
    const resp = await axios.post(`${API}/breakPlan/archive`, breakData);
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

async function getSearchOldAchive(breakData) {
  try {
    const resp = await axios.post(`${API}/breakPlan/oldarchive`, breakData);
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

async function getSearchBreakArch(breakData, city, rem, street) {
  try {
    const resp = await axios.get(
      `https://journal.toe.com.ua:9898/apiastor/archive/list?date=${breakData}&city=${city}&rem=${rem}&street=${street}`,
      { timeout: 15000 }
    );
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

// IKC ROUTING

async function getAdressRems() {
  try {
    const resp = await axios.get(`${API}/ikc/rems/`);
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

async function postIkcSupp(values) {
  try {
    const resp = await axios.post(`${API}/ikc/newSup/`, values);
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

async function getTeams() {
  try {
    const resp = await axios.get(`${API}/ikc/teams/`);
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

async function getHourTime(values, remid, themaid) {
  try {
    const resp = await axios.get(
      `${API}/ikc/getTime/${values}/${remid}/${themaid}`
    );
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

async function getRemsforCounter() {
  try {
    const resp = await axios.get(`${API}/counter/district`);
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

async function postCounterPokaz(body) {
  try {
    const resp = await axios.post(`${API}/counter/addPokaz`, body);
    return resp.data;
  } catch (error) {
    Notiflix.Notify.failure(
      'Вибачте виникла невідома помилка, спробуйте ще раз!'
    );
  }
}

async function getRegionGroup(city) {
  try {
    const resp = await axios.get(`${API}/breakGroup/region/${city}`);
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

async function getGraph(dataParams) {
  try {
    const resp = await axios.get(`${API}/breakGraph/${dataParams}`);
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

async function getStreatGroup(streat, cityId) {
  try {
    const resp = await axios.get(
      `${API}/breakGroup/streat/${streat}/${cityId}`
    );
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

async function getHomeGroup(streat) {
  try {
    const resp = await axios.get(`${API}/breakGroup/home/${streat}/`);
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

async function getBreakGroup({ streat, home }) {
  try {
    const resp = await axios.post(`${API}/breakGroup/`, {
      streetId: streat,
      houseParam: home,
    });
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

//

export {
  getArchive,
  getGraph,
  getHomeGroup,
  getBreakGroup,
  getStreatGroup,
  getRegionGroup,
  getSearchOldAchive,
  getAllNews,
  getAllCategory,
  getNewsById,
  postSearchNewsByName,
  getAllNewsFind,
  getSearchBreakPlan,
  getSearchBreakEmergency,
  getSearchBreakArch,
  getAdressRems,
  postIkcSupp,
  getHourTime,
  getTeams,
  getRemsforCounter,
  postCounterPokaz,
};
