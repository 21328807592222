import { Autocomplete, Button, TextField } from '@mui/material';
import {
  getBreakGroup,
  getGraph,
  getHomeGroup,
  getRegionGroup,
  getStreatGroup,
} from 'components/serviceApi/service';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { AiOutlineArrowUp } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const BreakGroup = () => {
  const [region, setRegion] = useState([]);
  const [streat, setStreat] = useState([]);
  const [graph, setGraph] = useState([]);
  const [home, setHome] = useState([]);
  const [breakGroup, setBreakGroup] = useState(null);
  const [find, setFind] = useState(false);

  const formik = useFormik({
    initialValues: {
      region: '',
      streat: '',
      home: '',
    },
    onSubmit: values => {
      if (!values.region.cityName && !values.streat.name) return;
      setFind(true);
      getBreakGroup({
        otg: values.region.otgId,
        city: values.region.cityId,
        streat: values.streat.id,
        home: values.home.house,
      }).then(resp => setBreakGroup(resp[0]));
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRegionFetch = useCallback(
    debounce(async value => {
      if (value) {
        try {
          const data = await getRegionGroup(value.trimStart());
          setRegion(data);
        } catch (error) {
          console.error('Error fetching region data:', error);
        }
      }
    }, 1000),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedStreatFetch = useCallback(
    debounce(async (value, otgId) => {
      if (value) {
        try {
          const data = await getStreatGroup(value, otgId);
          setStreat(data);
        } catch (error) {
          console.error('Error fetching street data:', error);
        }
      }
    }, 1000),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHomeFetch = useCallback(
    debounce(async (streat, value) => {
      if (value) {
        try {
          const data = await getHomeGroup(streat);
          setHome(data);
        } catch (error) {
          console.error('Error fetching home data:', error);
        }
      }
    }, 1000),
    []
  );

  const changeRegion = value => {
    formik.setFieldValue('region', value || '');
    formik.setFieldValue('streat', '');
    formik.setFieldValue('home', '');
    setStreat([]);
    setHome([]);
    setBreakGroup(null);
    setFind(false);
    debouncedRegionFetch(value);
  };

  const changeStreat = value => {
    formik.setFieldValue('streat', value || '');
    formik.setFieldValue('home', '');
    setHome([]);
    setBreakGroup(null);
    setFind(false);
    debouncedStreatFetch(value, formik.values.region.cityId);
  };

  const changeHome = value => {
    formik.setFieldValue('home', value || '');
    setBreakGroup(null);
    setFind(false);
    debouncedHomeFetch(formik.values.streat.id, value);
  };

  useEffect(() => {
    if (formik.values.region.cityId) {
      getStreatGroup(
        formik.values.streat || 'а',
        formik.values.region.cityId
      ).then(resp => setStreat(resp));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.region.cityId]);

  useEffect(() => {
    if (formik.values.streat.id) {
      getHomeGroup(formik.values.streat.id, ' ').then(resp => setHome(resp));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.streat.id]);

  useEffect(() => {
    getGraph(dayjs(new Date()).format('YYYY-MM-DD')).then(resp =>
      setGraph(resp[0].value)
    );
  }, []);

  return (
    <div className="page__section">
      <h2>Перерви в електропостачанні</h2>
      <Link className="page__btn" to={'/'}>
        <AiOutlineArrowUp /> на головну
      </Link>
      <div className="break-plan">
        <form onSubmit={formik.handleSubmit} className="break-plan__form">
          <h3
            style={{
              fontSize: '28px',
              fontWeight: '600',
              color: '#000',
              fontFamily: 'sans-serif',
              textAlign: 'center',
            }}
          >
            Пошук вашої черги
          </h3>
          <Autocomplete
            id="region"
            onChange={(_, value) => changeRegion(value)}
            onBlur={formik.handleBlur}
            renderInput={params => (
              <TextField
                {...params}
                label="Населений пункт"
                name="region"
                onChange={e => changeRegion(e.target.value.trim())}
                value={
                  formik.values.region.cityName + formik.values.region.otgName
                }
              />
            )}
            options={region}
            getOptionLabel={option =>
              `${option.cityName} (${option.otgName} отг)`
            }
            noOptionsText="Не знайдено"
          />
          {formik.values.region && (
            <Autocomplete
              id="streat"
              onChange={(_, value) => changeStreat(value)}
              onBlur={formik.handleBlur}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Вулиця"
                  name="streat"
                  onChange={e => changeStreat(e.target.value)}
                  value={formik.values.streat.name}
                />
              )}
              options={streat}
              getOptionLabel={option => option.name}
              noOptionsText="Не знайдено"
            />
          )}
          {formik.values.streat && (
            <Autocomplete
              id="home"
              onChange={(_, value) => changeHome(value)}
              onBlur={formik.handleBlur}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Будинок"
                  name="home"
                  onChange={e => changeHome(e.target.value)}
                  value={formik.values.home.house}
                />
              )}
              options={home}
              getOptionLabel={option => option.house}
              noOptionsText="Не знайдено"
            />
          )}
          <Button
            disabled={
              !formik.values.region.cityName ||
              !formik.values.streat.name ||
              !formik.values.home.house
            }
            variant="contained"
            type="submit"
          >
            Пошук
          </Button>
        </form>
        {breakGroup?.queue ? (
          <>
            <h3
              className="page__detals"
              style={{
                backgroundColor: '#fafac6',
                textAlign: 'center',
                padding: '30px',
                marginBottom: '50px',
                color: '#1976d2',
                fontWeight: '800',
              }}
            >
              Ваша адреса відноситься до {breakGroup.queue} черги
            </h3>

            <div style={{ color: '#000', margin: '30px 0' }}>
              <p
                className="page__detals"
                style={{ textAlign: 'center', margin: '15px 0' }}
              >
                Вимкнення електроенергії відбудеться в наступні години:
              </p>
              {graph.length !== 0 &&
                graph
                  .split('\n')
                  .filter(gr => gr.includes(`${breakGroup.queue}черга`))
                  .map((gr, index) => (
                    <p
                      key={index}
                      style={{ textAlign: 'center' }}
                      className="page__detals"
                    >
                      {gr}
                    </p>
                  ))}
            </div>
          </>
        ) : (
          find && (
            <h3
              className="page__detals"
              style={{
                backgroundColor: '#fafac6',
                textAlign: 'center',
                padding: '30px',
                marginBottom: '50px',
                color: '#1976d2',
                fontWeight: '800',
              }}
            >
              Черга невідома
            </h3>
          )
        )}
        {!find && (
          <div style={{ color: '#000', margin: '30px 0' }}>
            {graph.length !== 0 &&
              graph.split('\n').map(gr => (
                <p style={{ textAlign: 'center' }} className="page__detals">
                  {gr}
                </p>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BreakGroup;
