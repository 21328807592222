import { AiOutlineArrowUp } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { FiCircle } from 'react-icons/fi';
import { FcCallback } from 'react-icons/fc';
import { useState, useEffect } from 'react';
import Select from './Selec';
import {
  getAdressRems,
  getHourTime,
  getTeams,
  postIkcSupp,
} from 'components/serviceApi/service';
import Notiflix from 'notiflix';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import moment from 'moment/moment';
import uk from 'date-fns/locale/uk';
registerLocale('uk', uk);

const validationSchema = Yup.object().shape({
  theme: Yup.object({
    id: Yup.number().required('Виберіть тему звернення'),
    name: Yup.string()
      .notOneOf(['Тема звернення'], 'Виберіть тему звернення')
      .required('Виберіть тему звернення'),
  }).required('Виберіть тему звернення'),
  ikc: Yup.object({
    id: Yup.number().required('Виберіть адресу центру обслуговування клієнтів'),
    name: Yup.string()
      .notOneOf(
        ['Адреса центру обслуговування клієнтів:'],
        'Виберіть адресу центру обслуговування клієнтів'
      )
      .required('Виберіть адресу центру обслуговування клієнтів'),
    graffic: Yup.string(),
    phone: Yup.string(),
    rem: Yup.string(),
  }).required('Виберіть адресу центру обслуговування клієнтів'),
  client: Yup.string().required('Виберіть статус клієнта'),
  date: Yup.date().required(),
  hour: Yup.string()
    .notOneOf(['Виберіть час відвідування'], 'Виберіть час відвідування')
    .required('Виберіть годину прийому'),
  pib: Yup.string()
    .min(8, 'ПІБ користувача повинен складатися мінімум з 8 символів')
    .required('Вкажіть свій ПІБ'),
  email: Yup.string()
    .email('Не валідний email!')
    .required('Вкажіть свій email'),
  phone: Yup.string()
    .min(13, 'Номер телефону має включати 13 символів починаючи з +380...')
    .max(13)
    .required('Вкажіть свій телефон'),
  addressClient: Yup.string()
    .min(5, 'Адреса має складатися мінімум з 5 символів')
    .max(1000, 'Велика кількість символів!')
    .required('Вкажіть свою адресу'),
  ikcProblem: Yup.string()
    .min(20, 'Мінімальна кількість символів становить 20')
    .max(1000, 'Велика кількість символів!'),
});

const workTime = [
  '8:00',
  '8:30',
  '9:00',
  '9:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
];

const isWeekend = date => {
  const dayOfWeek = date.getDay();
  return dayOfWeek === 0 || dayOfWeek === 6; // 0 - неділя, 6 - субота
};

const getTomorrowDateWithoutWeekends = () => {
  let tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
  // Перевіряємо, чи завтра вихідний день
  while (isWeekend(tomorrow)) {
    // Якщо завтра вихідний, додаємо один день
    tomorrow = new Date(tomorrow.setDate(tomorrow.getDate() + 1));
  }

  return tomorrow;
};

const IkcPage = () => {
  const [address, setAddress] = useState([]);
  const [legal, setLegal] = useState([]);
  const [household, setHousehold] = useState([]);
  const [time, setTime] = useState([]);
  const [date, setDate] = useState(getTomorrowDateWithoutWeekends());

  const dateSelect = date => {
    handleSelectChange('date', date);
    setDate(date);
  };

  const isWeekday = date => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  // Стейт форми покупки для відправки
  const initialValues = {
    theme: { name: 'Тема звернення', id: '' },
    ikc: { name: 'Адреса ЦОК', id: '' },
    client: '',
    date: getTomorrowDateWithoutWeekends(),
    hour: 'Виберіть час відвідування',
    pib: '',
    email: '',
    phone: '',
    addressClient: '',
    personNumber: '',
    ikcProblem: '',
  };

  useEffect(() => {
    getTeams().then(resp => {
      const nameThemaLegal = resp.filter(resp => resp.u_type === 1);
      const nameThemaHousehold = resp.filter(resp => resp.u_type === 2);
      setLegal(nameThemaLegal);
      setHousehold(nameThemaHousehold);
    });
    getAdressRems().then(resp => {
      setAddress(resp);
    });
  }, []);

  // Відправка форми після замовлення
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: values => {
      if (
        values.ikcProblem.length < 20 &&
        values.ikcProblem.length !== 0 &&
        formik.values.theme.id === 12
      ) {
        // Валідація мінімальної довжини
        return Notiflix.Notify.failure(
          'Питання має містити щонайменше 20 символів'
        );
      }
      if (formik.values.theme.id !== 12) values.ikcProblem = '';

      postIkcSupp(values).then(resp => {
        if (resp.success === true) {
          formik.resetForm();
          Notiflix.Report.success(
            'Ви успішно записалися на прийом!',
            `Очікуйте повідомлення про підтвердження/скасування на вказану email адрес`,
            'Ок'
          );
          return;
        }
        Notiflix.Report.errors('Виникла помилка, спробуйте ще раз!');
      });
    },
  });

  const beforeSubmit = e => {
    e.preventDefault();
    if (formik.errors.ikc) {
      Notiflix.Notify.failure(`${formik.errors.ikc.id}`);
    }
    if (formik.errors.theme) {
      Notiflix.Notify.failure(`${formik.errors.theme.id}`);
    }
    if (formik.errors.client) {
      Notiflix.Notify.failure(`${formik.errors.client}`);
    }
    if (formik.errors.hour) {
      Notiflix.Notify.failure(`${formik.errors.hour}`);
    }
    if (formik.errors.phone) {
      Notiflix.Notify.failure(`${formik.errors.phone}`);
    }
    if (formik.errors.pib) {
      Notiflix.Notify.failure(`${formik.errors.pib}`);
    }
    if (formik.errors.email) {
      Notiflix.Notify.failure(`${formik.errors.email}`);
    }
    if (formik.errors.addressClient) {
      Notiflix.Notify.failure(`${formik.errors.addressClient}`);
    }
    if (formik.errors.ikcProblem) {
      Notiflix.Notify.failure(`${formik.errors.ikcProblem}`);
    }
    formik.handleSubmit();
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
  };

  const handleSelectChange = (name, option) => {
    if (name === 'client') {
      formik.setFieldValue('theme', { name: 'Тема звернення', id: '' });
      formik.setFieldValue('ikc', {
        name: 'Виберіть тему звернення',
        id: '',
      });
      formik.setFieldValue('hour', 'Виберіть час відвідування');
    }
    if (name === 'ikc') {
      formik.setFieldValue('hour', 'Виберіть час відвідування');
    }
    if (name === 'theme') {
      formik.setFieldValue('ikc', { name: 'Виберіть тему звернення', id: 0 });
      formik.setFieldValue('hour', 'Виберіть час відвідування');
    }
    return formik.setFieldValue(name, option);
  };

  useEffect(() => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    if (formik.values.ikc.id && formik.values.hour && formik.values.theme.id) {
      getHourTime(
        formattedDate,
        formik.values.ikc.id,
        formik.values.theme.id
      ).then(resp => setTime(resp));
    }
  }, [date, formik.values.hour, formik.values.ikc.id, formik.values.theme.id]);

  return (
    <section className="page">
      <div className="page__section">
        <h1>
          <FcCallback />
          Записатися в центр обслуговування клієнтів
        </h1>
        <div className="page__section--edit">
          <Link className="page__btn" to="/">
            <AiOutlineArrowUp /> назад
          </Link>
        </div>
      </div>
      <form className="formIkc" onSubmit={beforeSubmit}>
        <div>
          <p className="formIkc--p">
            Я клієнт
            <span style={{ color: 'red', fontFamily: 'sans-serif' }}>*</span>
          </p>
          <div className="fomrIkc--client">
            <label htmlFor="1" className="labelForm__ikc ">
              <p className="inputForm--ikc">Побутовий</p>
              <input
                type="radio"
                name="client"
                id="1"
                style={{ display: 'none' }}
                onClick={() => handleSelectChange('client', '1')}
              />
              {formik.values.client === '1' ? (
                <IoIosCheckmarkCircle
                  style={{ position: 'absolute', top: '12px', right: '15px' }}
                />
              ) : (
                <FiCircle
                  style={{ position: 'absolute', top: '12px', right: '15px' }}
                />
              )}
            </label>
            <label htmlFor="2" className="labelForm__ikc ">
              <p className="inputForm--ikc">Юридичний</p>
              <input
                type="radio"
                name="client"
                id="2"
                value={'2'}
                style={{ display: 'none' }}
                onClick={() => handleSelectChange('client', '2')}
              />
              {formik.values.client === '2' ? (
                <IoIosCheckmarkCircle
                  style={{ position: 'absolute', top: '12px', right: '15px' }}
                />
              ) : (
                <FiCircle
                  style={{ position: 'absolute', top: '12px', right: '15px' }}
                />
              )}
            </label>
          </div>
        </div>
        <div className="fomrIkc--client">
          <Select
            selectMap={
              (formik.values.client === '1' && household) ||
              (formik.values.client === '2' && legal) || [
                { name: 'ВИБЕРІТЬ ТИП КЛІЄНТА' },
              ]
            }
            label="Тема звернення:"
            selectedOption={formik.values.theme.name}
            name="theme"
            onSelect={option => handleSelectChange('theme', option)}
            client={formik.values.client}
          />
        </div>
        {formik.values.theme.id === 12 && (
          <div className="fomrIkc--select">
            <p className="formIkc--p">
              Щодо питання:
              <span style={{ color: 'red', fontFamily: 'sans-serif' }}>*</span>
            </p>
            <label htmlFor="ikcProblem" className="labelForm__ikc">
              <textarea
                required
                autocomplete="off"
                type="text"
                id="ikcProblem"
                name="ikcProblem"
                className="inputForm--ikc labelForm__ikc"
                placeholder="Ваше питання"
                onBlur={formik.handleBlur}
                value={formik.values.ikcProblem}
                onChange={handleInputChange}
                style={
                  formik.errors.ikcProblem &&
                  formik.touched.ikcProblem && {
                    background: 'rgba(255, 0, 0, 0.159)',
                  }
                }
              />
            </label>
            {formik.errors.ikcProblem && formik.touched.ikcProblem && (
              <span className="errorText">{formik.errors.ikcProblem}</span>
            )}
          </div>
        )}
        <div className="fomrIkc--client">
          <div className="fomrIkc--select">
            <p className="formIkc--p">
              Адреса:
              <span style={{ color: 'red', fontFamily: 'sans-serif' }}>*</span>
            </p>
            <label
              htmlFor="addressClient"
              className="labelForm__ikc"
              style={
                formik.errors.addressClient &&
                formik.touched.addressClient && {
                  background: 'rgba(255, 0, 0, 0.159)',
                }
              }
            >
              <input
                maxLength={100}
                autocomplete="off"
                required
                type="text"
                id="addressClient"
                name="addressClient"
                className="inputForm--ikc"
                placeholder="Адреса"
                onBlur={formik.handleBlur}
                value={formik.values.addressClient}
                onChange={handleInputChange}
              />
            </label>
            {formik.errors.addressClient && formik.touched.addressClient && (
              <span className="errorText">{formik.errors.addressClient}</span>
            )}
          </div>
          <div className="fomrIkc--select">
            <p className="formIkc--p">Особовий рахунок:</p>
            <label htmlFor="personNumber" className="labelForm__ikc">
              <input
                maxLength={10}
                autocomplete="off"
                type="tel"
                id="personNumber"
                name="personNumber"
                className="inputForm--ikc"
                placeholder="0000"
                onBlur={formik.handleBlur}
                value={formik.values.personNumber}
                onChange={handleInputChange}
              />
            </label>
          </div>
        </div>
        <div className="fomrIkc--client">
          <div className="fomrIkc--select">
            <p className="formIkc--p">
              ПІБ:
              <span style={{ color: 'red', fontFamily: 'sans-serif' }}>*</span>
            </p>
            <label
              htmlFor="pib"
              className="labelForm__ikc"
              style={
                formik.errors.pib &&
                formik.touched.pib && { background: 'rgba(255, 0, 0, 0.159)' }
              }
            >
              <input
                maxLength={100}
                autocomplete="off"
                required
                type="text"
                id="pib"
                name="pib"
                className="inputForm--ikc"
                placeholder="Прізвище Ім’я по Батькові "
                onBlur={formik.handleBlur}
                value={formik.values.pib}
                onChange={handleInputChange}
              />
            </label>
            {formik.errors.pib && formik.touched.pib && (
              <span className="errorText">{formik.errors.pib}</span>
            )}
          </div>
          <div className="fomrIkc--select">
            <p className="formIkc--p">
              Телефон:
              <span style={{ color: 'red', fontFamily: 'sans-serif' }}>*</span>
            </p>
            <label
              htmlFor="tel"
              className="labelForm__ikc"
              style={
                formik.errors.phone &&
                formik.touched.phone && {
                  background: 'rgba(255, 0, 0, 0.159)',
                }
              }
            >
              <input
                maxLength={13}
                autocomplete="off"
                required
                type="tel"
                id="tel"
                name="phone"
                className="inputForm--ikc"
                placeholder="+380"
                onBlur={formik.handleBlur}
                value={formik.values.name}
                onChange={handleInputChange}
              />
            </label>
            {formik.errors.phone && formik.touched.phone && (
              <span className="errorText">{formik.errors.phone}</span>
            )}
          </div>
        </div>
        <div className="fomrIkc--client">
          <div className="fomrIkc--select">
            <p className="formIkc--p">
              Email:
              <span style={{ color: 'red', fontFamily: 'sans-serif' }}>*</span>
            </p>
            <label
              htmlFor="pib"
              className="labelForm__ikc"
              style={
                formik.errors.email &&
                formik.touched.email && { background: 'rgba(255, 0, 0, 0.159)' }
              }
            >
              <input
                maxLength={100}
                autocomplete="off"
                required
                type="email"
                id="pib"
                name="email"
                className="inputForm--ikc"
                placeholder="Введіть електрону адресу"
                onBlur={formik.handleBlur}
                value={formik.values.name}
                onChange={handleInputChange}
              />
            </label>
            {formik.errors.email && formik.touched.email && (
              <span className="errorText">{formik.errors.email}</span>
            )}
          </div>
          <div className="fomrIkc--select">
            <Select
              selectMap={
                formik.values.theme.id
                  ? address
                  : [{ name: 'Виберіть тему звернення', id: 0 }]
              }
              label="Адреса центру обслуговування клієнтів:"
              selectedOption={formik.values.ikc.name}
              onSelect={option => handleSelectChange('ikc', option)}
            />
          </div>
        </div>
        <div className="fomrIkc--client">
          <div className="fomrIkc--select">
            <p className="formIkc--p">
              Виберіть дату:
              <span style={{ color: 'red', fontFamily: 'sans-serif' }}>*</span>
            </p>
            <label htmlFor="date" className="labelForm__ikc">
              <div className="inputForm--ikc">
                <DatePicker
                  locale="uk"
                  onBlur={formik.handleBlur}
                  selected={formik.values.date}
                  onChange={date => dateSelect(date)}
                  filterDate={isWeekday}
                  dateFormat="dd-MM-yyyy"
                  minDate={
                    new Date(new Date().setDate(new Date().getDate() + 1))
                  }
                  maxDate={
                    new Date(new Date().getTime() + 9 * 24 * 60 * 60 * 1000)
                  }
                />
              </div>
            </label>
          </div>
          <div className="fomrIkc--select">
            <Select
              selectMap={
                (formik.values.ikc.id &&
                  workTime.filter(timeZone => !time.includes(timeZone))) || [
                  'ВИБЕРІТЬ ЦОК',
                ]
              }
              label="Виберіть час відвідування:"
              selectedOption={formik.values.hour}
              onSelect={option => handleSelectChange('hour', option)}
            />
          </div>
        </div>
        <label className="checkForm" htmlFor="checkBox">
          <input
            type="checkbox"
            id="checkBox"
            className="checkForm--box"
            required
          />
          <p>
            Під час повітряної тривоги центри обслуговування клієнтів
            призупиняють прийом. Якщо час вашого прийому співпав з повітряною
            тривогою, то після її закінчення ви будете прийняті з першим
            пріоритетом. Погоджуюсь з правилами безпечного відвідування офісу
            обслуговування та даю згоду на отримання, зберігання і обробку
            персональних даних.
          </p>
        </label>
        <button className="btnSendIkc" type="submit">
          Записатися
        </button>
      </form>
    </section>
  );
};

export default IkcPage;
