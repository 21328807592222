import { Autocomplete, Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { AiOutlineArrowUp } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import rems from './listRem.json';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import {
  getSearchBreakArch,
  getSearchBreakEmergency,
  getSearchBreakPlan,
  getSearchOldAchive,
} from 'components/serviceApi/service';
import { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect } from 'react';
import 'dayjs/locale/uk';
import {
  TextBreak,
  TextNotFount,
  FormPlan,
  FormTdName,
  OldFormPlan,
  FormOldTdName,
} from './textForBreak';
import { ThreeDots } from 'react-loader-spinner';
import ReCAPTCHA from 'react-google-recaptcha';
import { isArray } from 'lodash';

const PageBreaks = () => {
  const firstDayOfMonth = dayjs();
  const lastDayOfMonth = dayjs()
    .add(2, 'month')
    .startOf('month')
    .subtract(1, 'day');
  const [breakPlanDate, setBreakPlanDate] = useState([]);
  const [nameBreaks, setNameBreaks] = useState({
    label: 'ПОТОЧНІ(Аварійні)  ВІДКЛЮЧЕННЯ',
    id: 2,
  });
  const [find, setFind] = useState(false);
  const [findFirst, setFindFirst] = useState(false);
  const [captcha, setCaptcha] = useState(true);
  const [ResultObject, setResultObject] = useState([]);

  const [ctrlPressed, setCtrlPressed] = useState(false);
  const [enterPressed, setEnterPressed] = useState(false);

  useEffect(() => {
    const groupedObjects = {};

    breakPlanDate &&
      isArray(breakPlanDate) &&
      breakPlanDate.forEach(obj => {
        const key = `${obj.Streat}-${obj.DateBeg}`;

        if (!groupedObjects[key]) {
          groupedObjects[key] = {
            Rem: obj.Rem,
            DNO: obj.DNO,
            City: obj.City,
            DateBeg: obj.DatePlan || obj.DateBeg,
            Pruch: obj.Pruch,
            DateEnd: obj.DateOff || obj.DateEnd,
            DateCreate: obj.DateCreate,
            Streat: obj.Streat,
            NumBud: obj.NumBud ? new Set(obj.NumBud.split(' ')) : new Set(),
            TypeOff: obj.TypeOff,
          };
        } else {
          if (obj.NumBud) {
            obj.NumBud.split(' ').forEach(num =>
              groupedObjects[key].NumBud.add(num)
            );
          }
        }
      });

    const result = Object.values(groupedObjects)
      .map(item => ({
        ...item,
        NumBud: Array.from(item.NumBud).join(' '),
      }))
      .sort((a, b) => a.Streat.localeCompare(b.Streat));

    setResultObject(result);
  }, [breakPlanDate]);

  const formik = useFormik({
    initialValues: {
      date: dayjs(),
      rem: rems[0].label,
      city: '',
      streat: '',
      apartament: '',
    },
    onSubmit: values => {
      const postPlan = {
        dateOff: values.date.format('DD.MM.YYYY'),
        city: values.city,
        rem: values.rem,
        streat: values.streat,
      };
      setBreakPlanDate([]);
      setFind(true);
      if (nameBreaks.id === 2 && values.city === 'абра кадабра%') {
        return getSearchBreakEmergency({
          dateOff: dayjs().format('DD.MM.YYYY'),
          city: 'абра кадабра',
          rem: '',
          streat: '',
        }).then(
          resp => setBreakPlanDate(resp),
          setTimeout(() => {
            setFind(false);
          }, 5000),
          setFindFirst(true)
        );
      }
      if (nameBreaks.id === 1 && values.city === 'абра кадабра%') {
        return getSearchBreakPlan({
          dateOff: values.date.format('DD.MM.YYYY'),
          city: 'абра кадабра',
          rem: '',
          streat: '',
        }).then(
          resp => setBreakPlanDate(resp),
          setTimeout(() => {
            setFind(false);
          }, 5000),
          setFindFirst(true)
        );
      }
      if (nameBreaks.id === 2) {
        getSearchBreakEmergency(postPlan).then(
          resp => setBreakPlanDate(resp),
          setTimeout(() => {
            setFind(false);
          }, 2000),
          setFindFirst(true)
        );
      } else if (nameBreaks.id === 1) {
        getSearchBreakPlan(postPlan).then(
          resp => setBreakPlanDate(resp),
          setTimeout(() => {
            setFind(false);
          }, 2000),
          setFindFirst(true)
        );
      } else if (nameBreaks.id === 4) {
        getSearchOldAchive({
          dateOff: values.date.format('YYYY-MM-DD'),
          city: values.city,
          rem: rems.find(rem => rem.label === values.rem).id,
          streat: values.streat,
        }).then(
          resp => resp && setResultObject(resp),
          setTimeout(() => {
            setFind(false);
          }, 2000),
          setFindFirst(true)
        );
      } else {
        getSearchBreakArch(
          values.date.format('DD.MM.YYYY'),
          values.city,
          rems.find(rem => rem.label === values.rem).id,
          values.streat
        ).then(
          resp => {
            setBreakPlanDate(resp ? resp : []);
            setTimeout(() => {
              setFind(false);
            }, 10000);
            setFindFirst(true);
          },
          error => {
            console.error(
              'Сталася помилка під час виклику API або обробки відповіді:',
              error
            );

            setBreakPlanDate([]);
          }
        );
      }
    },
  });

  const onChangeTypeSubmit = value => {
    setNameBreaks(value);
    setFindFirst(false);
    setFind(false);
    setResultObject([]);
    setBreakPlanDate([]);
    formik.resetForm();
    if (value.id === 4 || value.id === 3) {
      formik.setFieldValue('date', dayjs('31.01.2024'));
    }
  };

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'p') {
        setCtrlPressed(true);
      } else if (event.key === '5') {
        setEnterPressed(true);
      }
    };

    const handleKeyUp = event => {
      if (event.key === 'p') {
        setCtrlPressed(false);
      } else if (event.key === '5') {
        setEnterPressed(false);
      }
    };

    const handleKeyPressCombination = event => {
      if (ctrlPressed && enterPressed) {
        event.preventDefault();
        formik.handleSubmit();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    window.addEventListener('keypress', handleKeyPressCombination);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('keypress', handleKeyPressCombination);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctrlPressed, enterPressed]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="uk">
      <div className="page__section">
        <h2>Перерви в електропостачанні</h2>
        <Link className="page__btn" to={'/'}>
          <AiOutlineArrowUp /> на головну
        </Link>
        <div className="break-plan">
          <h3 className="break-plan__title">
            Дата та час оновлення інформації про вимкнення:{' '}
            {dayjs().format('HH')}
            :00
          </h3>
          <form onSubmit={formik.handleSubmit} className="break-plan__form">
            <Autocomplete
              id="breaks-id"
              value={nameBreaks.label}
              onChange={(_, value) => onChangeTypeSubmit(value)}
              options={[
                { label: 'ПОТОЧНІ(Аварійні) ВІДКЛЮЧЕННЯ', id: 2 },
                { label: 'ПЛАНОВІ ВІДКЛЮЧЕННЯ', id: 1 },
                // { label: 'АРХІВ ВІДКЛЮЧЕНЬ ВІД 2024 Р.', id: 3 },
                // { label: 'АРХІВ ВІДКЛЮЧЕНЬ ДО 2024 Р.', id: 4 },
              ]}
              renderInput={params => (
                <TextField {...params} label="Відключення" />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disableClearable
            />
            <DatePicker
              value={formik.values.date}
              views={['day']}
              onChange={value => formik.setFieldValue('date', value)}
              format="DD/MM/YYYY"
              disabled={nameBreaks.id === 2 ? true : false}
              minDate={
                nameBreaks.id === 3
                  ? dayjs('2024.01.31')
                  : nameBreaks.id === 1
                  ? firstDayOfMonth
                  : ''
              }
              maxDate={
                nameBreaks.id === 3
                  ? firstDayOfMonth.subtract(1, 'day')
                  : nameBreaks.id === 4
                  ? dayjs('2024.01.31')
                  : lastDayOfMonth
              }
              sx={nameBreaks.id === 2 ? { display: 'none' } : ''}
            />

            <Autocomplete
              disablePortal
              id="rem"
              value={formik.values.rem}
              onChange={(_, value) => formik.setFieldValue('rem', value.label)}
              onBlur={formik.handleBlur}
              options={rems}
              renderInput={params => <TextField {...params} label="РЕМ" />}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disableClearable
            />
            <TextField
              id="city"
              label={
                nameBreaks.id === 4
                  ? 'Населений пункт/Вулиця'
                  : 'Населений пункт'
              }
              variant="outlined"
              value={formik.values.city}
              onChange={e =>
                formik.setFieldValue('city', e.target.value.trimStart())
              }
              onBlur={formik.handleBlur}
              required
            />
            {nameBreaks.id !== 4 && (
              <TextField
                id="streat"
                label="Вулиця"
                variant="outlined"
                value={formik.values.streat}
                onChange={e =>
                  formik.setFieldValue('streat', e.target.value.trimStart())
                }
                onBlur={formik.handleBlur}
              />
            )}
            {nameBreaks.id !== 4 && (
              <TextField
                id="apartament"
                label="Будинок"
                variant="outlined"
                value={formik.values.apartament}
                onChange={e =>
                  formik.setFieldValue('apartament', e.target.value.trimStart())
                }
                onBlur={formik.handleBlur}
              />
            )}
            <div style={{ margin: '0 auto' }}>
              <ReCAPTCHA
                sitekey="6LeITTUpAAAAAPPVs0nKFWqyr_i-FcwzMFekVftT"
                onChange={val => setCaptcha(val)}
              />
            </div>
            <Button variant="contained" type="submit" disabled={!captcha}>
              Пошук
            </Button>
          </form>
          {ResultObject && ResultObject.length > 0 && (
            <div>
              {TextBreak(formik, nameBreaks.id)}
              <table
                border="1"
                cellSpacing={0}
                cellPadding={0}
                className="tableBreak"
              >
                <tbody className="planBreak">
                  {window.innerWidth >= 601 &&
                    nameBreaks.id !== 4 &&
                    FormTdName(nameBreaks.id)}
                  {nameBreaks.id === 4 &&
                    window.innerWidth >= 601 &&
                    FormOldTdName(nameBreaks.id)}
                  {ResultObject &&
                  ResultObject.length !== 0 &&
                  nameBreaks.id !== 4
                    ? ResultObject.map(
                        ({
                          City,
                          DNO,
                          DateBeg,
                          DateEnd,
                          DateCreate,
                          Pruch,
                          Rem,
                          Streat,
                          NumBud,
                          TypeOff,
                        }) => {
                          return (
                            Streat &&
                            FormPlan(
                              City,
                              DateBeg,
                              DateEnd,
                              DateCreate,
                              Pruch,
                              Rem,
                              Streat,
                              NumBud,
                              DNO,
                              nameBreaks.id,
                              TypeOff
                            )
                          );
                        }
                      )
                    : ResultObject.map(
                        ({
                          City,
                          DNO,
                          DateBeg,
                          DateEnd,
                          DateCreate,
                          Pruch,
                          Rem,
                        }) => {
                          return OldFormPlan(
                            City,
                            DateBeg,
                            DateEnd,
                            DateCreate,
                            Pruch,
                            Rem,
                            nameBreaks.id
                          );
                        }
                      )}
                </tbody>
              </table>
            </div>
          )}
          {!find &&
            ResultObject.length === 0 &&
            findFirst &&
            TextNotFount(formik.values)}
        </div>
      </div>
      {find && ResultObject && ResultObject.length === 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // height: '100vh',
          }}
        >
          <ThreeDots
            height={80}
            width={80}
            color="#337ab7"
            ariaLabel="circles-loading"
            visible={true}
          />
        </div>
      )}

      {ResultObject?.rem === 'Error' && (
        <h3
          className="page__detals"
          style={{
            backgroundColor: '#fafac6',
            textAlign: 'center',
            padding: '30px',
            marginBottom: '50px',
            color: '#000',
          }}
        >
          Проводяться технічні роботи
        </h3>
      )}
    </LocalizationProvider>
  );
};
export default PageBreaks;
