import React, { useState } from 'react';
import axios from 'axios';
import './vts.css';

const ElectricityConnectionForm = () => {
  const [formData, setFormData] = useState({
    districtId: '',
    areaTypeId: '1',
    electricalInstallationTypeId: '1',
    reliabilityCategoryId: '1',
    connectionPointVoltageId: '2',
    connectionPointLocationId: '1',
    connectionKindId: '1',
    powerLineTypeId: '1',
    power: '',
    length: '',
  });

  const [resultStandart, setResultStandart] = useState(null);
  const [resultNonStandart, setResultNonStandart] = useState(null);

  const handleInputChange = e => {
    console.log(e.target.value);
    console.log(e.target.name);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setResultStandart(null);
    setResultNonStandart(null);

    const { power, length } = formData;

    try {
      if (power > 0 && power <= 50 && length > 0 && length <= 300) {
        const response = await axios.get(
          'https://journal.toe.com.ua:9898/api/siteInfo/paymentRateStandart',
          {
            params: {
              connectionPointVoltageId: formData.connectionPointVoltageId,
              connectionKindId: formData.connectionKindId,
              areaTypeId: formData.areaTypeId,
              reliabilityCategoryId: formData.reliabilityCategoryId,
            },
          }
        );

        const tariff = response.data;
        if (tariff > 0) {
          setResultStandart({
            withPDV: (tariff * 1000 * power * 1.2).toFixed(2),
            PDV: (tariff * 1000 * power * 0.2).toFixed(2),
            tariff: (tariff * 1000).toFixed(2),
          });
        } else {
          alert('Невірно введені дані! Спробуйте ще раз');
        }
      } else {
        const response = await axios.get(
          'https://journal.toe.com.ua:9898/api/siteInfo/paymentRateNonStandart',
          {
            params: {
              connectionPointVoltageId: formData.connectionPointVoltageId,
              electricalInstallationTypeId:
                formData.electricalInstallationTypeId,
              connectionKindId: formData.connectionKindId,
              areaTypeId: formData.areaTypeId,
              reliabilityCategoryId: formData.reliabilityCategoryId,
              districtId: formData.districtId,
              powerLineTypeId: formData.powerLineTypeId,
              connectionPointLocationId: formData.connectionPointLocationId,
            },
          }
        );

        if (response.data.length === 2) {
          const [tariffPower, tariffLength] = response.data;
          setResultNonStandart({
            withPDV: (
              tariffPower * 1000 * power * 1.2 +
              tariffLength * 1000 * length * 1.2
            ).toFixed(2),
            PDV: (
              tariffPower * 1000 * power * 0.2 +
              tariffLength * 1000 * length * 0.2
            ).toFixed(2),
            withPDVPower: (tariffPower * 1000 * power * 1.2).toFixed(2),
            PDVPower: (tariffPower * 1000 * power * 0.2).toFixed(2),
            tariffPower: (tariffPower * 1000).toFixed(2),
            withPDVLength: (tariffLength * 1000 * length * 1.2).toFixed(2),
            PDVLength: (tariffLength * 1000 * length * 0.2).toFixed(2),
            tariffLength: (tariffLength * 1000).toFixed(2),
          });
        } else {
          alert('Невірно введені дані! Спробуйте ще раз');
        }
      }
    } catch (error) {
      alert('Помилка при отриманні даних! Спробуйте ще раз.');
    }
  };

  return (
    <div className="containerCalc my-5">
      <form onSubmit={handleSubmit} className="bg-light p-4 rounded shadow-sm">
        <div className="form-group">
          <label htmlFor="districtId">Район електричних мереж:</label>
          <select
            name="districtId"
            value={formData.districtId}
            onChange={handleInputChange}
            className="form-control"
          >
            <option value="">Оберіть район</option>
            <option value="1">Бережанський РЕМ</option>
            <option value="2">Борщівський РЕМ</option>
            <option value="3">Бучацький РЕМ</option>
            <option value="4">Гусятинський РЕМ</option>
            <option value="5">Заліщицький РЕМ</option>
            <option value="6">Збаразький РЕМ</option>
            <option value="7">Зборівський РЕМ</option>
            <option value="8">Козівський РЕМ</option>
            <option value="9">Кременецький РЕМ</option>
            <option value="10">Лановецький РЕМ</option>
            <option value="11">Монастириський РЕМ</option>
            <option value="12">Підволочиський РЕМ</option>
            <option value="13">Підгаєцький РЕМ</option>
            <option value="14">Теребовлянський РЕМ</option>
            <option value="15">Тернопільський РЕМ</option>
            <option value="16">Тернопільський міський РЕМ</option>
            <option value="17">Чортківський РЕМ</option>
            <option value="18">Шумський РЕМ</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="areaTypeId">Місцевість:</label>

          <select
            name="areaTypeId"
            value={formData.areaTypeId}
            onChange={handleInputChange}
            className="form-control"
          >
            <option value="1">місто</option>
            <option value="2">село</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="electricalInstallationTypeId">
            Тип електроустановки:
          </label>
          <select
            name="electricalInstallationTypeId"
            value={formData.electricalInstallationTypeId}
            onChange={handleInputChange}
            className="form-control"
          >
            <option value="1">споживання</option>
            <option value="2">виробництво</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="reliabilityCategoryId">
            Категорія надійності електропостачання:
          </label>
          <select
            name="reliabilityCategoryId"
            value={formData.reliabilityCategoryId}
            onChange={handleInputChange}
            className="form-control"
          >
            <option value="1">І категорія надійності</option>
            <option value="2">ІІ категорія надійності</option>
            <option value="3">ІІІ категорія надійності</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="connectionPointVoltageId">
            Ступінь напруги в точці приєднання:
          </label>
          <select
            name="connectionPointVoltageId"
            value={formData.connectionPointVoltageId}
            onChange={handleInputChange}
            className="form-control"
          >
            <option value="2">0.4 кВ</option>
            <option value="3">10 кВ</option>
            <option
              value="4"
              disabled={formData.connectionKindId === '2' ? false : true}
            >
              35 кВ
            </option>
            <option
              value="5"
              disabled={formData.connectionKindId === '2' ? false : true}
            >
              110 кВ
            </option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="connectionPointLocationId">
            Розташування точки приєднання:
          </label>
          <select
            name="connectionPointLocationId"
            value={formData.connectionPointLocationId}
            onChange={handleInputChange}
            className="form-control"
          >
            <option value="1">на межі земельної ділянки</option>
            <option value="2">на земельній ділянці</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="connectionKindId">Кількість фаз приєднання:</label>
          <select
            name="connectionKindId"
            onChange={handleInputChange}
            value={formData.connectionKindId}
            className="form-control"
          >
            <option value="1">однофазне</option>
            <option value="2">трифазне</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="powerLineTypeId">Тип лінії електропередавання:</label>
          <select
            name="powerLineTypeId"
            value={formData.powerLineTypeId}
            onChange={handleInputChange}
            className="form-control"
          >
            <option value="1">повітряна</option>
            <option value="2">кабельна</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="power">Потужність, замовлена до приєднання:</label>
          <input
            type="number"
            name="power"
            value={formData.power}
            onChange={handleInputChange}
            min="0"
            className="form-control"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="length" className="labelForm__ikc">
            Відстань м.:
          </label>
          <input
            type="number"
            name="length"
            value={formData.length}
            onChange={handleInputChange}
            min="0"
            className="form-control "
            required
          />
        </div>

        <button
          type="submit"
          className="btn btn-primary btn-block"
          style={{ marginTop: '15px', marginLeft: 'auto' }}
        >
          Розрахувати
        </button>
      </form>

      {resultStandart && (
        <div className="mt-4 p-3 bg-success text-white rounded">
          <h3>Орієнтовна вартість стандартного приєднання:</h3>
          <p>Вартість: {resultStandart.withPDV} грн. з ПДВ</p>
          <p>в т.ч. ПДВ: {resultStandart.PDV} грн.</p>
          <p>Тариф: {resultStandart.tariff} грн./1 кВт. без ПДВ</p>
        </div>
      )}

      {resultNonStandart && (
        <div className="mt-4 p-3 bg-danger text-white rounded">
          <h3>Орієнтовна вартість нестандартного приєднання "під ключ":</h3>
          <p>Вартість: {resultNonStandart.withPDV} грн. з ПДВ</p>
          <p>в т.ч. ПДВ: {resultNonStandart.PDV} грн.</p>
          <p>
            - плата за потужність: {resultNonStandart.withPDVPower} грн. з ПДВ
          </p>
          <p>в т.ч. ПДВ: {resultNonStandart.PDVPower} грн.</p>
          <p>Тариф: {resultNonStandart.tariffPower} грн./1 кВт. без ПДВ</p>
          <p>
            - плата за лінійну частину: {resultNonStandart.withPDVLength} грн. з
            ПДВ
          </p>
          <p>в т.ч. ПДВ: {resultNonStandart.PDVLength} грн.</p>
          <p>Тариф: {resultNonStandart.tariffLength} грн./1 м. без ПДВ</p>
        </div>
      )}
    </div>
  );
};

export default ElectricityConnectionForm;
