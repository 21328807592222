import { AiOutlineArrowUp } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import uk from 'date-fns/locale/uk';
import {
  addMonths,
  endOfMonth,
  setDate,
  startOfMonth,
  isBefore,
  isAfter,
} from 'date-fns';
import React, { useEffect, useState } from 'react';
import { FaTelegramPlane, FaViber } from 'react-icons/fa';
import { getRemsforCounter } from 'components/serviceApi/service';
import dayjs from 'dayjs';
import Notiflix from 'notiflix';
import ReCAPTCHA from 'react-google-recaptcha';
import { PokazService } from 'components/serviceApi/pokaz.service';
registerLocale('uk', uk);

const zipNumberReg = /^2(3[0-9]|4[0-4]|2[7-9])[0-9]{7}$/;

const validationSchema = Yup.object().shape({
  date: Yup.date().required(),
  counter: Yup.string()
    .matches(/^\d+$/, 'Показник повинен містити тільки цифри')
    .required('Вкажіть показник'),
  phone: Yup.string()
    .min(13, 'Номер телефону має включати 13 символів починаючи з +380...')
    .max(13),
  lastName: Yup.string()
    .min(2, 'Прізвище має складатися мінімум з 2 символів')
    .max(1000, 'Велика кількість символів!')
    .required('Вкажіть своє прізвище'),
  zipNumber: Yup.string()
    .min(10, 'Особовий рахунок має складатися з 10 символів')
    .max(10, 'Особовий рахунок має складатися з 10 символів')
    .matches(zipNumberReg, 'Некоректно введений особовий рахунок')
    .required('Вкажіть свій особовий рахунок 2(код району)(О/Р)'),
});

const CounerPage = () => {
  const [rems, setRems] = useState(null);
  const [captcha, setCaptcha] = useState(false);
  const [pokazMinus, setPokazMinus] = useState(false);
  const [dataUser, setDataUser] = useState();
  const [generateUser, setGenerateUser] = useState(false);
  const [offDay, setOffday] = useState(true);

  useEffect(() => {
    const today = new Date();
    const day = today.getDate();

    if (day >= 4 && day <= 7) {
      setOffday(false);
    } else {
      setOffday(true);
    }
  }, []);

  const dateSelect = date => {
    handleSelectChange('date', date);
  };

  // Отримати РЕМИ
  useEffect(() => {
    getRemsforCounter().then(resp => setRems(resp));
  }, []);

  // Стейт форми покупки для відправки
  const initialValues = {
    lastName: '',
    zipNumber: '',
    typeCounter: null,
    phone: '',
    date: dayjs().$d,
    counter: 'Відсутній',
    counterLunch: 'Відсутній',
    counterNight: 'Відсутній',
    meterNumber: '',
    energyKindCode: 'A-',
  };
  //
  // Відправка форми після замовлення
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => {
      if (checkTooMuchPokaz() > 30000) return alert('Розхід більший норми!');
      if (
        generateUser &&
        prompt(
          'Ваш особовий рахунок з генерацію, будь ласка вкажіть номер лічильника щоб підтвердити передачу показників, або скористайтеся особистим кабінетом!'
        ) !== values.meterNumber
      )
        return Notiflix.Report.failure(
          'Помилка',
          'Неправильний номер лічильника, показник не передано!',
          'Добре'
        );
      if (
        checkTooMuchPokaz() > 3000 &&
        prompt(
          'Ваш розхід перевищив 3 тис. кВт*год, ведіть номер лічильника для підтвердження передачі показників, або скористайтеся особистим кабінетом!'
        ) !== values.meterNumber
      )
        return Notiflix.Report.failure(
          'Помилка',
          'Неправильний номер лічильника, показник не передано!',
          'Добре'
        );

      PokazService.addPokaz({
        accountNumber: values.zipNumber,
        meterNumber: values.meterNumber,
        energyKindCode: values.energyKindCode,
        date: dayjs(values.date).format('YYYY-MM-DD'),
        originDetailId: 107,
        value: `${values.counter}${
          !isNaN(values.counterNight) ? `/${values.counterNight}` : ''
        }${!isNaN(values.counterLunch) ? `/${values.counterLunch}` : ''}`,
        note: values.lastName + ' ' + values.phone,
      }).then(resp => {
        Notiflix.Report.success('Дякуємо', 'Показ прийнято', 'Ок');
        formik.resetForm();
        setDataUser();
        setGenerateUser(false);
      });
    },
  });

  const handleInputChange = event => {
    const { name, value } = event.target;

    const numericValue =
      name === 'counter' || name === 'counterNight' || name === 'counterLanche'
        ? Number(value)
        : value;

    if (name === 'zipNumber' && value.length >= 3) {
      formik.setFieldValue('rem', { rem: '', id: value.slice(1, 3) });
      setDataUser();
      setGenerateUser(false);
      formik.resetForm();
    }
    formik.setFieldValue(name, numericValue);
  };

  const handleSelectChange = (name, option) => {
    return formik.setFieldValue(name, option);
  };

  const today = new Date();
  const dayOfMonth = today.getDate();
  const lastDayOfCurrentMonth = endOfMonth((today, 1));
  const secondLastDayOfPreviousMonth = setDate(
    lastDayOfCurrentMonth,
    lastDayOfCurrentMonth.getDate() - 1
  );

  const filterDate = date => {
    const fourthDayOfCurrentMonth = new Date(
      today.getFullYear(),
      today.getMonth(),
      4
    );

    // Блокуємо всі дати від 4 числа поточного місяця до сьогоднішнього дня
    if (isBefore(date, fourthDayOfCurrentMonth) || isAfter(date, today)) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (dayOfMonth <= 3) {
      formik.setFieldValue('date', startOfMonth(today));
    } else if (
      dayOfMonth === secondLastDayOfPreviousMonth.getDate() ||
      dayOfMonth === lastDayOfCurrentMonth.getDate()
    ) {
      formik.setFieldValue('date', startOfMonth(addMonths(today, 1)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCyrillicInputChange = event => {
    const { name, value } = event.target;
    const cyrillicPattern = /^[А-Яа-яЁёІіЇїЄєҐґ\s]*$/;

    if (cyrillicPattern.test(value) || value === '') {
      formik.setFieldValue(name, value);
    }
  };

  useEffect(() => {
    const fetchResp = async () => {
      if (formik.values.zipNumber.length === 10) {
        try {
          const res = await PokazService.getDataPokaz(formik.values.zipNumber);
          if (res.length > 1) {
            return Notiflix.Report.warning(
              'Увага',
              `Для користувачів з генерацією, просимо використовувати особистий кабінет користувача!`,
              'Дякую'
            );
          }
          if (res[0]) {
            setDataUser(res[0]);
          } else {
            Notiflix.Report.failure('Помилка', res.message, 'Добре');
          }
        } catch (e) {
          Notiflix.Report.failure(
            'Помилка',
            'Немає даних по особовому рахунку, перевірте номер рахунку, або зверніться в кол-центр компанії...',
            'Змінити'
          );
          setDataUser();
          setGenerateUser(false);
        }
      }
    };
    fetchResp();
  }, [formik.values.zipNumber]);

  useEffect(() => {
    if (dataUser) {
      handleInputChange({
        target: {
          value: dataUser?.lastMeterReadValue.split('/').length,
          name: 'typeCounter',
        },
      });
      handleInputChange({
        target: {
          value: dataUser?.meterNumber,
          name: 'meterNumber',
        },
      });
      handleInputChange({
        target: {
          value: dataUser?.energyKindCode,
          name: 'energyKindCode',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUser]);

  useEffect(() => {
    if (!dataUser?.lastMeterReadValue) return; // Перевірка на undefined

    const [сounter, lastCounterNight, lastCounterLunch] =
      dataUser.lastMeterReadValue.split('/').map(val => parseInt(val, 10) || 0); // Запобігаємо NaN

    if (
      checkTooMuchPokaz() < 0 ||
      (formik.values.counter && formik.values.counter < сounter) ||
      (formik.values.counterNight &&
        formik.values.counterNight < lastCounterNight) ||
      (formik.values.counterLunch &&
        formik.values.counterLunch < lastCounterLunch)
    ) {
      setPokazMinus(true);
    } else {
      setPokazMinus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.counter,
    formik.values.counterNight,
    formik.values.counterLunch,
    dataUser?.lastMeterReadValue, // Додаємо залежність
  ]);

  const checkTooMuchPokaz = () => {
    if (!dataUser) return;

    // Сума всіх значень lastMeterReadValue
    const allPokaz = dataUser?.lastMeterReadValue
      .split('/')
      .map(p => parseInt(p.split(/[,.]/)[0], 10) || 0)
      .reduce((acc, num) => acc + num, 0);

    // Формуємо `test`, де всі значення приводимо до числа або 0
    const test =
      (!isNaN(formik.values.counter) ? Number(formik.values.counter) : 0) +
      (!isNaN(formik.values.counterNight)
        ? Number(formik.values.counterNight)
        : 0) +
      (!isNaN(formik.values.counterLunch)
        ? Number(formik.values.counterLunch)
        : 0);
    return test - allPokaz;
  };

  const mathCounterDif = (formik, pokaz) => {
    const numberValue = parseInt(pokaz.split(/[,.]/)[0], 10) || 0;
    return formik - numberValue;
  };

  return (
    <section className="page">
      <div className="page__section">
        <h1 style={{ display: 'flex', justifyContent: 'space-between' }}>
          Подати показник 
          <span style={{ fontSize: '14px' }}>
            Рекомендованим є внесення показів щомісяця, станом на 1-ше число
          </span>
        </h1>
        <div className="page__section--edit">
          <Link className="page__btn" to="/">
            <AiOutlineArrowUp /> назад
          </Link>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          fontSize: '20px',
          gap: '12px',
          alignItems: 'flex-end',
          padding: '0 18px',
        }}
      >
        <Link to="/news/viber" target="_blank">
          <FaViber />  Подати показник через Viber
        </Link>
        <Link to="https://t.me/TOE_counter_bot" target="_blank">
          <FaTelegramPlane /> Подати показник через Telegram
        </Link>
        {formik.errors.zipNumber && (
          <div>
            <Link
              style={{
                fontFamily: 'sans-serif',
                margin: '24px 10px 0px 10px',
                textAlign: 'center',
              }}
              target="_blank"
              to="/news/327"
            >
              Інструкція для подання показника
            </Link>
          </div>
        )}
      </div>
      <p
        style={{
          fontFamily: 'sans-serif',
          fontWeight: 'bold',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          margin: '24px 10px 0px 10px',
          textAlign: 'center',
        }}
      >
        {formik.values.zipNumber.length >= 3 && 'Подача показників: '}
        {(rems &&
          formik.values.zipNumber.length >= 3 &&
          rems.find(
            rem => rem.id === Number(formik.values.zipNumber.slice(1, 3))
          )?.name) ||
          (formik.values.zipNumber.length >= 3 && 'невідомого РЕМ')}
      </p>
      {offDay ? (
        <form className="formIkc" onSubmit={formik.handleSubmit}>
          <div>
            <div className="info__aboutUser">
              <div className="info__aboutUser--block">
                <div className="fomrIkc--select">
                  <p className="formIkc--p">
                    Особовий рахунок: 10 символів <br />
                    "2 (
                    <Link
                      style={{
                        margin: '10px 5px 5px 10px',
                        fontFamily: 'sans-serif',
                      }}
                      target="_blank"
                      to="/news/327"
                    >
                      код району
                    </Link>
                    ) (особовий рахунок)"
                    <span style={{ color: 'red', fontFamily: 'sans-serif' }}>
                      *
                    </span>
                  </p>
                  <label
                    htmlFor="zipNumber"
                    className="labelForm__ikc"
                    style={
                      formik.errors.zipNumber &&
                      formik.touched.zipNumber && {
                        background: 'rgba(255, 0, 0, 0.159)',
                      }
                    }
                  >
                    <input
                      onWheel={e => e.currentTarget.blur()}
                      required
                      autoComplete="on"
                      maxLength={12}
                      type="number"
                      id="zipNumber"
                      name="zipNumber"
                      className="inputForm--ikc"
                      placeholder="2*********"
                      onBlur={formik.handleBlur}
                      value={formik.values.zipNumber}
                      onChange={handleInputChange}
                      title="2270121212 - приклад Бережанського РЕМ"
                    />
                  </label>

                  {formik.errors.zipNumber && (
                    <span className="errorText">{formik.errors.zipNumber}</span>
                  )}
                </div>
                <div className="fomrIkc--select">
                  <p className="formIkc--p">
                    Прізвище (кирилицею):
                    <span style={{ color: 'red', fontFamily: 'sans-serif' }}>
                      *
                    </span>
                    <br />
                  </p>
                  <label
                    htmlFor="lastName"
                    className="labelForm__ikc"
                    style={
                      formik.errors.lastName &&
                      formik.touched.lastName && {
                        background: 'rgba(255, 0, 0, 0.159)',
                      }
                    }
                  >
                    <input
                      maxLength={100}
                      required
                      type="text"
                      id="lastName"
                      name="lastName"
                      className="inputForm--ikc"
                      placeholder="Прізвище"
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                      onChange={handleCyrillicInputChange}
                    />
                  </label>
                  {formik.errors.lastName && formik.touched.lastName && (
                    <span className="errorText">{formik.errors.lastName}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="fomrIkc--client">
            {dataUser ? (
              formik.values.typeCounter === 1 ? (
                <div className="fomrIkc--select">
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <p className="formIkc--p" style={{ textAlign: 'end' }}>
                      Фактичний показник:{' '}
                      <span style={{ color: 'red', fontFamily: 'sans-serif' }}>
                        *
                      </span>
                    </p>
                    <p className="formIkc--p" style={{ textAlign: 'end' }}>
                      на 
                      {dayjs(dataUser.lastMeterReadDate).format('DD.MM.YYYY')}:
                      <br />
                      <b>{dataUser?.lastMeterReadValue}</b>
                    </p>
                  </div>
                  <label
                    htmlFor="counter"
                    className="labelForm__ikc"
                    style={
                      formik.errors.counter &&
                      formik.touched.counter && {
                        background: 'rgba(255, 0, 0, 0.159)',
                      }
                    }
                  >
                    <input
                      maxLength={20}
                      required
                      type="number"
                      id="counter"
                      name="counter"
                      className="inputForm--ikc"
                      placeholder="Показник"
                      onBlur={formik.handleBlur}
                      value={formik.values.counter}
                      onChange={handleInputChange}
                    />
                  </label>
                  {formik.errors.counter && formik.touched.counter && (
                    <span className="errorText">{formik.errors.counter}</span>
                  )}
                  {!isNaN(formik.values.counter) &&
                    dataUser?.lastMeterReadValue && (
                      <p className="formIkc--p" style={{ marginTop: '8px' }}>
                        Споживання кВт*год:
                        <span style={{ fontWeight: 'bold' }}>
                          {mathCounterDif(
                            formik.values.counter,
                            dataUser?.lastMeterReadValue
                          )}
                        </span>
                      </p>
                    )}
                </div>
              ) : formik.values.typeCounter === 2 ? (
                <>
                  <div className="fomrIkc--select">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p className="formIkc--p">
                        Фактичний показник (День):
                        <span
                          style={{ color: 'red', fontFamily: 'sans-serif' }}
                        >
                          *
                        </span>
                      </p>
                      <p className="formIkc--p" style={{ textAlign: 'end' }}>
                        на 
                        {dayjs(dataUser.lastMeterReadDate).format('DD.MM.YYYY')}
                        :<br />
                        <b>{dataUser?.lastMeterReadValue.split('/')[0]}</b>
                      </p>
                    </div>
                    <label
                      htmlFor="counter"
                      className="labelForm__ikc"
                      style={
                        formik.errors.counter &&
                        formik.touched.counter && {
                          background: 'rgba(255, 0, 0, 0.159)',
                        }
                      }
                    >
                      <input
                        maxLength={20}
                        required
                        type="number"
                        id="counter"
                        name="counter"
                        className="inputForm--ikc"
                        placeholder="Показник (День)"
                        onBlur={formik.handleBlur}
                        value={formik.values.counter}
                        onChange={handleInputChange}
                      />
                    </label>
                    {formik.errors.counter && formik.touched.counter && (
                      <span className="errorText">{formik.errors.counter}</span>
                    )}
                    {!isNaN(formik.values.counter) &&
                      dataUser?.lastMeterReadValue.split('/')[0] && (
                        <p className="formIkc--p" style={{ marginTop: '8px' }}>
                          Споживання кВт*год:
                          <span style={{ fontWeight: 'bold' }}>
                            {mathCounterDif(
                              formik.values.counter,
                              dataUser?.lastMeterReadValue.split('/')[0]
                            )}
                          </span>
                        </p>
                      )}
                  </div>
                  <div className="fomrIkc--select">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p className="formIkc--p" style={{ textAlign: 'end' }}>
                        Фактичний показник (Ніч):
                        <span
                          style={{ color: 'red', fontFamily: 'sans-serif' }}
                        >
                          *
                        </span>
                      </p>
                      <p className="formIkc--p" style={{ textAlign: 'end' }}>
                        на 
                        {dayjs(dataUser.lastMeterReadDate).format('DD.MM.YYYY')}
                        :
                        <br />
                        <b>{dataUser?.lastMeterReadValue.split('/')[1]}</b>
                      </p>
                    </div>
                    <label
                      htmlFor="counterNight"
                      className="labelForm__ikc"
                      style={
                        formik.errors.counter &&
                        formik.touched.counter && {
                          background: 'rgba(255, 0, 0, 0.159)',
                        }
                      }
                    >
                      <input
                        maxLength={20}
                        required
                        type="number"
                        id="counterNight"
                        name="counterNight"
                        className="inputForm--ikc"
                        placeholder="Показник (Ніч)"
                        onBlur={formik.handleBlur}
                        value={formik.values.counterNight}
                        onChange={handleInputChange}
                      />
                    </label>
                    {formik.errors.counter && formik.touched.counter && (
                      <span className="errorText">{formik.errors.counter}</span>
                    )}
                    {!isNaN(formik.values.counterNight) &&
                      dataUser?.lastMeterReadValue.split('/')[1] && (
                        <p className="formIkc--p" style={{ marginTop: '8px' }}>
                          Споживання кВт*год: {' '}
                          <span style={{ fontWeight: 'bold' }}>
                            {mathCounterDif(
                              formik.values.counterNight,
                              dataUser?.lastMeterReadValue.split('/')[1]
                            )}
                          </span>
                        </p>
                      )}
                  </div>
                </>
              ) : (
                <>
                  <div className="fomrIkc--select">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '30px',
                      }}
                    >
                      <p className="formIkc--p" style={{ textAlign: 'end' }}>
                        Фактичний показник (Пік):
                        <span
                          style={{ color: 'red', fontFamily: 'sans-serif' }}
                        >
                          *
                        </span>
                      </p>
                      <p className="formIkc--p" style={{ textAlign: 'end' }}>
                        на 
                        {dayjs(dataUser.lastMeterReadDate).format('DD.MM.YYYY')}
                        :
                        <br />
                        {dataUser?.lastMeterReadValue.split('/')[0]}
                      </p>
                    </div>
                    <label
                      htmlFor="counter"
                      className="labelForm__ikc"
                      style={
                        formik.errors.counter &&
                        formik.touched.counter && {
                          background: 'rgba(255, 0, 0, 0.159)',
                        }
                      }
                    >
                      <input
                        maxLength={20}
                        required
                        type="number"
                        id="counter"
                        name="counter"
                        className="inputForm--ikc"
                        placeholder="Показник (Пік)"
                        onBlur={formik.handleBlur}
                        value={formik.values.counter}
                        onChange={handleInputChange}
                      />
                    </label>
                    {formik.errors.counter && formik.touched.counter && (
                      <span className="errorText">{formik.errors.counter}</span>
                    )}
                    {!isNaN(formik.values.counter) &&
                      dataUser?.lastMeterReadValue.split('/')[0] && (
                        <p className="formIkc--p" style={{ marginTop: '8px' }}>
                          Споживання кВт*год: {' '}
                          <span style={{ fontWeight: 'bold' }}>
                            {mathCounterDif(
                              formik.values.counter,
                              dataUser?.lastMeterReadValue.split('/')[0]
                            )}
                          </span>
                        </p>
                      )}
                  </div>
                  <div className="fomrIkc--select">
                    {' '}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '30px',
                      }}
                    >
                      <p className="formIkc--p" style={{ textAlign: 'end' }}>
                        Фактичний показник (Напівпік):
                        <span
                          style={{ color: 'red', fontFamily: 'sans-serif' }}
                        >
                          *
                        </span>
                      </p>
                      <p className="formIkc--p" style={{ textAlign: 'end' }}>
                        на 
                        {dayjs(dataUser.lastMeterReadDate).format('DD.MM.YYYY')}
                        :
                        <br />
                        {dataUser?.lastMeterReadValue.split('/')[1]}
                      </p>
                    </div>
                    <label
                      htmlFor="counterNight"
                      className="labelForm__ikc"
                      style={
                        formik.errors.counter &&
                        formik.touched.counter && {
                          background: 'rgba(255, 0, 0, 0.159)',
                        }
                      }
                    >
                      <input
                        maxLength={20}
                        required
                        type="number"
                        id="counterNight"
                        name="counterNight"
                        className="inputForm--ikc"
                        placeholder="Показник (Напівпік)"
                        onBlur={formik.handleBlur}
                        value={formik.values.counterNight}
                        onChange={handleInputChange}
                      />
                    </label>
                    {formik.errors.counter && formik.touched.counter && (
                      <span className="errorText">{formik.errors.counter}</span>
                    )}
                    {!isNaN(formik.values.counterNight) &&
                      dataUser?.lastMeterReadValue.split('/')[1] && (
                        <p className="formIkc--p" style={{ marginTop: '8px' }}>
                          Споживання кВт*год: {' '}
                          <span style={{ fontWeight: 'bold' }}>
                            {mathCounterDif(
                              formik.values.counterNight,
                              dataUser?.lastMeterReadValue.split('/')[1]
                            )}
                          </span>
                        </p>
                      )}
                  </div>
                  <div className="fomrIkc--select">
                    {' '}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '30px',
                      }}
                    >
                      <p className="formIkc--p" style={{ textAlign: 'end' }}>
                        Фактичний показник (Ніч):
                        <span
                          style={{ color: 'red', fontFamily: 'sans-serif' }}
                        >
                          *
                        </span>
                      </p>
                      <p className="formIkc--p" style={{ textAlign: 'end' }}>
                        на 
                        {dayjs(dataUser.lastMeterReadDate).format('DD.MM.YYYY')}
                        :
                        <br />
                        {dataUser?.lastMeterReadValue.split('/')[2]}
                      </p>
                    </div>
                    <label
                      htmlFor="counterLunch"
                      className="labelForm__ikc"
                      style={
                        formik.errors.counter &&
                        formik.touched.counter && {
                          background: 'rgba(255, 0, 0, 0.159)',
                        }
                      }
                    >
                      <input
                        maxLength={20}
                        required
                        type="number"
                        id="counterLunch"
                        name="counterLunch"
                        className="inputForm--ikc"
                        placeholder="Показник (Ніч)"
                        onBlur={formik.handleBlur}
                        value={formik.values.counterLunch}
                        onChange={handleInputChange}
                      />
                    </label>
                    {formik.errors.counter && formik.touched.counter && (
                      <span className="errorText">{formik.errors.counter}</span>
                    )}
                    {!isNaN(formik.values.counterLunch) &&
                      dataUser?.lastMeterReadValue.split('/')[2] && (
                        <p className="formIkc--p" style={{ marginTop: '8px' }}>
                          Споживання кВт*год:
                          <span style={{ fontWeight: 'bold' }}>
                            {' '}
                            {mathCounterDif(
                              formik.values.counterLunch,
                              dataUser?.lastMeterReadValue.split('/')[2]
                            )}
                          </span>
                        </p>
                      )}
                  </div>
                </>
              )
            ) : (
              ''
            )}
          </div>
          <div className="fomrIkc--client">
            <div className="fomrIkc--select">
              <p className="formIkc--p">
                Дата зняття показника:
                <span style={{ color: 'red', fontFamily: 'sans-serif' }}>
                  *
                </span>
              </p>
              <label htmlFor="date" className="labelForm__ikc">
                <div className="inputForm--ikc">
                  <DatePicker
                    customInput={<CustomInput />}
                    filterDate={filterDate}
                    locale="uk"
                    onBlur={formik.handleBlur}
                    selected={formik.values.date}
                    onChange={date => dateSelect(date)}
                    dateFormat="dd.MM.yyyy"
                  />
                </div>
              </label>
            </div>
            <div className="fomrIkc--select">
              <p className="formIkc--p">Телефон:</p>
              <label
                htmlFor="tel"
                className="labelForm__ikc"
                style={
                  formik.errors.phone &&
                  formik.touched.phone && {
                    background: 'rgba(255, 0, 0, 0.159)',
                  }
                }
              >
                <input
                  maxLength={13}
                  type="tel"
                  id="tel"
                  name="phone"
                  className="inputForm--ikc"
                  placeholder="+380"
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  onChange={handleInputChange}
                />
              </label>
              {formik.errors.phone && formik.touched.phone && (
                <span className="errorText">{formik.errors.phone}</span>
              )}
            </div>
          </div>
          {!isNaN(formik.values.counter) && pokazMinus && (
            <p
              style={{ color: 'red', textAlign: 'center' }}
              className="formIkc--p"
            >
              Показник менший попереднього не може бути прийнятий до розрахунку!
              Зверніться в кол-центр компанії, або скористайтеся 
              <Link
                to={'https://my.toe.com.ua/'}
                style={{ textDecoration: 'underline' }}
              >
                Особистим кабінетом
              </Link>
            </p>
          )}
          <div style={{ margin: '0 auto' }}>
            <ReCAPTCHA
              sitekey="6LeITTUpAAAAAPPVs0nKFWqyr_i-FcwzMFekVftT"
              onChange={val => setCaptcha(val)}
            />
          </div>
          <button
            disabled={!captcha || pokazMinus}
            className="btnSendIkc"
            type="submit"
            style={
              !captcha || pokazMinus
                ? { background: '#868686' }
                : { background: '' }
            }
          >
            Передати
          </button>
        </form>
      ) : (
        <div
          style={{
            display: 'flex',
            backgroundColor: '#fafac6',
            padding: '30px',
            margin: '125px auto',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h3
            className="page__detals"
            style={{
              color: 'gray',
            }}
          >
            Проводяться технічні роботи. Вибачте за тимчасові незручності.
          </h3>
        </div>
      )}
    </section>
  );
};

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <input
    readOnly
    value={value}
    onClick={onClick}
    ref={ref}
    style={{ cursor: 'pointer' }}
  />
));

export default CounerPage;
