import axios from 'axios';
import { API } from './service';

// const getToken = async () => {
//   try {
//     const response = await axios.get(`${API}/pokaz/token`);
//     return response.data;
//   } catch (error) {
//     console.error('Помилка отримання токену:', error);
//     throw error;
//   }
// };

export const PokazService = {
  async getDataPokaz(body) {
    try {
      const response = await axios.get(`${API}/pokaz/getUserData/${body}`);
      return response.data;
    } catch (error) {
      console.error('Помилка отримання даних:', error);
    }
  },

  async addPokaz(body) {
    try {
      const response = await axios.post(`${API}/pokaz/addPokaz/`, body);
      return response.data;
    } catch (error) {
      console.error('Помилка отримання даних:', error);
    }
  },
};
