import dayjs from 'dayjs';
import moment from 'moment';
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiFillTwitterSquare,
} from 'react-icons/ai';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export const TextBreak = (formik, id) => {
  return (
    <div className="info__forBrake">
      {id === 2 && (
        <>
          <p>
            <b>Інформація</b>
            <p>про причини тимчасового припинення електроживлення</p>в
            електроустановках електроживлення споживачів та про значні перерви в
            електропостачанні з кількістю відключених споживачів більше 100 та
            тривалістю понад годину
            <em className="italic"> :  </em>
            по <b>{formik.values.rem || 'всіх РЕМ'}</b>
          </p>
          <p>
            Поточні вимкнення на:  <b>{moment().format('DD.MM.YYYY')}</b>
            <p style={{ color: 'red' }}>
              Якщо зазначено лише вулицю без вказання номерів будинків, то
              вимкнення стосується всіх споживачів зазначеної вулиці
            </p>
          </p>
        </>
      )}
      {id === 1 && (
        <>
          <p>
            <b>Інформація</b>
            <p>про планові перерви в електропостачанні</p>
            <p>
              при проведенні робіт з експлуатаційного обслуговування
              електроустановок, приєднання нових споживачів
            </p>
            <p>
              або при виникненні режимів, безпосередньо не пов’язаних з аваріями
            </p>
            <em className="italic">
              (технологічними порушеннями в роботі електричних мереж)
            </em>
            по <b>{formik.values?.rem}</b>
          </p>
          <p>
            Планові вимкнення на:
            <b>{formik.values?.date.format('DD.MM.YYYY')}</b>
          </p>
        </>
      )}
      {id === 3 && (
        <>
          <p>
            Архів вимкнень на:
            <b>{formik.values?.date.format('DD.MM.YYYY')}</b>
          </p>
        </>
      )}
    </div>
  );
};

export const TextNotFount = val => {
  return (
    <h3
      className="page__detals"
      style={{
        backgroundColor: '#fafac6',
        textAlign: 'center',
        padding: '30px',
        marginBottom: '50px',
        color: '#000',
      }}
    >
      Вимкнення {'по ' + val.rem}
      {val.city && ' в населеному пункті ' + val.city}
      {val.streat && ' по вулиці ' + val.streat} відсутні
    </h3>
  );
};

function compare(a, b) {
  // Пробуємо перетворити рядки в числа
  const numA = parseInt(a, 10);
  const numB = parseInt(b, 10);
  if (!isNaN(numA) && !isNaN(numB)) {
    return numA - numB;
  }
  return a.localeCompare(b);
}

function highlightWord(list, wordToHighlight) {
  const highlightedText = list.replace(
    new RegExp(wordToHighlight, 'gi'),
    '<span style="background-color: yellow;">$&</span>'
  );
  return highlightedText;
}

function highlightWordStr(list, wordToHighlight) {
  const highlightedText = list.replace(
    new RegExp(wordToHighlight, 'gi'),
    '<span style="background-color: yellow;">$&</span>'
  );
  return highlightedText;
}

function highlightWordHouse(list, wordToHighlight) {
  const highlightedText = list
    .split(';')
    .sort(compare)
    .join(',')
    .replace(
      new RegExp(wordToHighlight, 'gi'),
      '<span style="background-color: yellow;">$&</span>'
    );
  return highlightedText;
}

export const OldFormPlan = (City, DateBeg, DateEnd, DateCreate, Pruch, Rem) => {
  return (
    <>
      {window.innerWidth >= 601 ? (
        City.length > 0 && (
          <tr>
            <td style={{ fontWeight: '900' }}>{Rem}</td>
            <td>{Pruch}</td>
            <td id="place">
              <p>
                <span>{City} </span>
              </p>
            </td>
            <td>{DateBeg}</td>
            <td>{DateCreate}</td>
          </tr>
        )
      ) : (
        <>
          <tr>
            <td className="breakPlan--mobile">РЕМ</td>
            <td>{Rem}</td>
          </tr>
          <tr>
            <td className="breakPlan--mobile">
              Причина перерви в електропостачанні
            </td>
            <td>{Pruch}</td>
          </tr>
          <tr>
            <td className="breakPlan--mobile">Населений пункт</td>
            <td>
              <span>{City} </span>
            </td>
          </tr>

          <tr>
            <td className="breakPlan--mobile">Запланований час вимкнення</td>
            <td> {DateCreate}</td>
          </tr>
          <tr>
            <td className="breakPlan--mobile">Очікуваний час ввімкнення</td>

            <td>{DateEnd}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
          </tr>
        </>
      )}
    </>
  );
};

export const FormOldTdName = () => {
  return (
    <thead
      style={{
        backgroundColor: '#337ab7',
        color: 'white',
        fontSize: '18px',
      }}
    >
      <tr>
        <th>
          <p>РЕМ</p>
        </th>
        <th>Причина перерви в електропостачанні</th>
        <th>Населений пункт</th>
        <th>Запланований час відновлення</th>
        <th>Запланований час вимкнення</th>
      </tr>
    </thead>
  );
};

export const FormPlan = (
  City,
  DateBeg,
  DateEnd,
  DateCreate,
  Pruch,
  Rem,
  Streat,
  NumBud,
  DNO,
  id,
  TypeOff
) => {
  const inputTextCity = document.getElementById('city').value;
  const highlightedList = highlightWord(City, inputTextCity);
  const inputTextStreat = document.getElementById('streat').value;
  const highlightedStr = highlightWordStr(Streat, inputTextStreat);
  const inputTextHouse = document.getElementById('apartament').value;
  const highlightedHouse = highlightWordHouse(NumBud, inputTextHouse);

  // function compare(a, b) {
  //   // Пробуємо перетворити рядки в числа
  //   const numA = parseInt(a, 10);
  //   const numB = parseInt(b, 10);
  //   if (!isNaN(numA) && !isNaN(numB)) {
  //     return numA - numB;
  //   }
  //   return a.localeCompare(b);
  // }

  // const adjustDateIfNeeded = (dateCreate, dateBeg) => {
  //   const dateFormat = 'DD.MM.YYYY HH:mm:ss'; // формат вхідних даних

  //   const dateCreateObj = dayjs(dateCreate, dateFormat);
  //   const dateBegObj = dayjs(dateBeg, dateFormat);
  //   const differenceInDays = dateBegObj.diff(dateCreateObj, 'day'); // різниця в днях

  //   // Якщо різниця менша за 5 днів, додаємо дні до DateCreate
  //   if (differenceInDays < 5) {
  //     const adjustedDateCreate = dateCreateObj.subtract(
  //       5 - differenceInDays,
  //       'day'
  //     );

  //     return (
  //       <td className={5 - differenceInDays}>
  //         {adjustedDateCreate.format('DD.MM.YYYY HH:mm') + '.'}
  //       </td>
  //     );
  //   }

  //   return <td>{dateCreateObj.format('DD.MM.YYYY HH:mm')}</td>; // залишаємо дату без змін
  // };

  return (
    <>
      {window.innerWidth >= 601
        ? City.length > 0 && (
            <tr>
              <td style={{ fontWeight: '900' }}>{Rem}</td>
              <td>{Pruch}</td>
              <td id="place">
                <p>
                  <span>{City.length > 0 && ''} </span>
                  <span dangerouslySetInnerHTML={{ __html: highlightedList }} />
                </p>
              </td>
              <td>
                <p>
                  <span>{Streat.length > 2 && ' '} </span>
                  <span dangerouslySetInnerHTML={{ __html: highlightedStr }} />
                </p>
              </td>
              <td>
                {/* {NumBud.length > 1 && NumBud.split(';').sort(compare).join(',')} */}
                <span dangerouslySetInnerHTML={{ __html: highlightedHouse }} />
              </td>
              <td>
                {id !== 2
                  ? DateBeg.slice(0, 11) === DateEnd.slice(0, 11)
                    ? DateBeg.slice(0, 16) + '-' + DateEnd.slice(11, 16)
                    : DateBeg.slice(0, 16) + '-' + DateEnd.slice(0, 16)
                  : DateBeg.slice(0, 16)}
              </td>
              {/* {id === 3 && TypeOff === 'Планова з попередженням'
                ? adjustDateIfNeeded(DateCreate, DateBeg) */}
              {id !== 2 && <td>{DateCreate.slice(0, 16)}</td>}
            </tr>
          )
        : City.length > 0 && (
            <>
              <tr>
                <td className="breakPlan--mobile">РЕМ</td>
                <td>{Rem}</td>
              </tr>
              <tr>
                <td className="breakPlan--mobile">
                  Причина перерви в електропостачанні
                </td>
                <td>{Pruch}</td>
              </tr>
              <tr>
                <td className="breakPlan--mobile">Населений пункт</td>
                <td>
                  <span dangerouslySetInnerHTML={{ __html: highlightedList }} />
                </td>
              </tr>
              <tr>
                <td className="breakPlan--mobile">Вулиця</td>
                <td>
                  {
                    <span
                      dangerouslySetInnerHTML={{ __html: highlightedStr }}
                    />
                  }
                </td>
              </tr>
              <tr>
                <td className="breakPlan--mobile">Будинок</td>
                <td>
                  {NumBud.length > 1 &&
                    NumBud.split(';').sort(compare).join(',')}
                </td>
              </tr>
              <tr>
                {id !== 2 ? (
                  <td className="breakPlan--mobile">
                    Запланований час вимкнення
                  </td>
                ) : (
                  <td className="breakPlan--mobile">
                    Очікуваний час ввімкнення
                  </td>
                )}
                <td>
                  {id !== 2
                    ? DateBeg.slice(0, 11) === DateEnd.slice(0, 11)
                      ? DateBeg.slice(0, 16) + '-' + DateEnd.slice(11, 16)
                      : DateBeg.slice(0, 16) + '-' + DateEnd.slice(0, 16)
                    : DateBeg.slice(0, 16)}
                </td>
              </tr>
              {id !== 2 && (
                <tr>
                  <td className="breakPlan--mobile">Дата додавання</td>
                  <td>{DateCreate.slice(0, 11)}</td>
                </tr>
              )}
              <tr>
                <td></td>
                <td></td>
              </tr>
            </>
          )}
    </>
  );
};

export const FormTdName = id => {
  return (
    <thead
      style={{
        backgroundColor: '#337ab7',
        color: 'white',
        fontSize: '18px',
      }}
    >
      <tr>
        <th>
          <p>РЕМ</p>
        </th>
        <th>Причина перерви в електропостачанні</th>
        <th>Населений пункт</th>
        <th>Вулиця</th>
        <th>Будинок</th>
        {id === 2 ? (
          <th>Очікуваний час ввімкнення</th>
        ) : (
          <th>Запланований час вимкнення</th>
        )}
        {id !== 2 && <th>Дата додавання</th>}
      </tr>
    </thead>
  );
};

export const LinkPublick = location => {
  return (
    <div className="social-page">
      <a
        className="social-page--link"
        target="_blank"
        rel="noreferrer noopener"
        href={`https://www.facebook.com/sharer.php?u=https://www.toe.com.ua${location.pathname}`}
      >
        <AiFillFacebook className="svg__footer--social" />
      </a>
      <a
        className="social-page--link"
        target="_blank"
        rel="noreferrer noopener"
        href={`https://twitter.com/intent/tweet?url=https://www.toe.com.ua${location.pathname}`}
      >
        <AiFillTwitterSquare className="svg__footer--social" />
      </a>
      <a
        className="social-page--link"
        target="_blank"
        rel="noreferrer noopener"
        href={`https://www.linkedin.com/shareArticle/?mini=true&url=https://www.toe.com.ua${location.pathname}`}
      >
        <AiFillLinkedin className="svg__footer--social" />
      </a>
    </div>
  );
};
